export default {
  namespaced: true,

  state: {
    list_subcategories: [],
    steps: [
      {
        id: 0,
        number: "01",
        number2: 1,
        name: "<strong class='font-black'>Tipo de </strong>finca",
        id_name: "TIPO_DE_FINCA",
      },
    ],
    stepsAdditional: [
      {
        name: "<strong class='font-black'>Videos </strong>y Fotos",
        id_name: "VIDEOS_FOTOS",
      },
      {
        name: "<strong class='font-black'>Previsualización </strong>",
        id_name: "PREVIEW",
      },
      {
        name: "<strong class='font-black'>Documentación </strong>Legal",
      },
      {
        name: "<strong class='font-black'>Destaca </strong>tu Finca",
      },
      {
        name: "<strong class='font-black'>Publicación </strong>Exitosa",
      },
    ],
    counterStep: 1,
    counter: 0,
    step: null,
    formData2: null,
    formData3: null,
    formData4: null,
    formData7: null,
    dataSave: null,
    abortController: null,
    checkConfirm: false,
    isTopography: false,
    isHeight: false,
    isStep: true,
    isSaveImage: false,
    isError: false,
    isSuccess: false,
    farmSave: {},
    multimedia: [],
    multimediaCopy: [],
    checkAuthorization: false,
    message:
      "¿Salir de la página? Si ya pasaste el paso 3 tu finca ha quedado guardada. De lo contrario si abandonas se perdera la información que llevas.",
  },

  mutations: {
    setSubcategory(state, payload) {
      state.list_subcategories = payload;
    },
    setFindStep(state, number) {
      const findIndex = state.steps.findIndex((el) => el.number2 == number);
      state.step = state.steps[findIndex];
    },
    setAddStep(state, payload) {
      state.steps = [];
      state.counterStep = 1;
      state.counter = 0;
      state.steps.push({
        id: 0,
        number: "01",
        number2: 1,
        name: "<strong class='font-black'>Tipo de </strong>finca",
        id_name: "TIPO_DE_FINCA",
      });

      payload.forEach((el) => {
        state.counterStep += 1;
        state.counter += 1;
        state.steps.push({
          id: el.id,
          number: `0${state.counterStep}`,
          number2: state.counterStep,
          name: `<strong class='font-black'>${el.name}</strong>`,
          steps: el.steps,
          id_name: el.id_name,
        });
      });
      state.stepsAdditional.forEach((el) => {
        state.counterStep += 1;
        state.counter += 1;
        state.steps.push({
          id: state.counter,
          number: `0${state.counterStep}`,
          number2: state.counterStep,
          name: el.name,
          id_name: el.id_name,
        });
      });
    },
    setFormData2(state, payload) {
      state.formData2 = { ...payload };
    },
    setFormData3(state, payload) {
      state.formData3 = { ...payload };
    },
    setFormData4(state, payload) {
      state.formData4 = { ...payload };
    },
    setFormData7(state, payload) {
      state.formData7 = { ...payload };
    },
    setCheckConfirm(state, payload) {
      state.checkConfirm = payload;
    },
    setIsTopography(state, payload) {
      state.isTopography = payload;
    },
    setIsHeight(state, payload) {
      state.isHeight = payload;
    },
    setDataSave(state, payload) {
      state.dataSave = payload;
    },
    setFarmSave(state, payload) {
      state.farmSave = payload;
    },
    setMultimedia(state, payload) {
      state.multimedia = payload;
    },
    setRestore(state) {
      state.steps = [];
      state.counterStep = 1;
      state.counter = 0;
      state.steps.push({
        id: 0,
        number: "01",
        number2: 1,
        name: "<strong class='font-black'>Tipo de </strong>finca",
      });

      state.list_subcategories = [];
      state.formData2 = null;
      state.formData3 = null;
      state.formData4 = null;
      state.formData7 = null;
      state.checkConfirm = false;
      state.isTopography = false;
      state.isHeight = false;
      state.dataSave = null;
      state.farmSave = {};
      state.multimedia = [];
      state.multimediaCopy = [];
      state.checkAuthorization = false;
      state.step = null;
      state.abortController = null;
      // state.isStep = true;
      state.isSaveImage = false;
      state.isError = false;
      state.isSuccess = false;
      state.message =
        "¿Salir de la página? Si ya pasaste el paso 3 tu finca ha quedado guardada. De lo contrario si abandonas se perdera la información que llevas.";
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setIsStep(state, payload) {
      state.isStep = payload;
    },
    setCheckAuthorization(state, payload) {
      state.checkAuthorization = payload;
    },
    setIsSaveImage(state, payload) {
      state.isSaveImage = payload;
    },
    setMultimediaCopy(state, payload) {
      state.multimediaCopy = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setAbortController(state, payload) {
      state.abortController = payload;
    },
    setIsError(state, payload) {
      state.isError = payload;
    },
    setIsSuccess(state, payload) {
      state.isSuccess = payload;
    },
  },

  actions: {
    addSubcategory({ commit }, payload) {
      commit("setSubcategory", payload);
    },
    findStep({ commit }, payload) {
      commit("setFindStep", payload);
    },
    addStep({ commit }, payload) {
      commit("setAddStep", payload);
    },
    addFormData2({ commit }, payload) {
      commit("setFormData2", payload);
    },
    addFormData3({ commit }, payload) {
      commit("setFormData3", payload);
    },
    addFormData4({ commit }, payload) {
      commit("setFormData4", payload);
    },
    addFormData7({ commit }, payload) {
      commit("setFormData7", payload);
    },
    addCheckConfim({ commit }, payload) {
      commit("setCheckConfirm", payload);
    },
    addIsTopography({ commit }, payload) {
      commit("setIsTopography", payload);
    },
    addIsHeight({ commit }, payload) {
      commit("setIsHeight", payload);
    },
    addDataSave({ commit }, payload) {
      commit("setDataSave", payload);
    },
    addFarmSave({ commit }, payload) {
      commit("setFarmSave", payload);
    },
    addMultimedia({ commit }, payload) {
      commit("setMultimedia", payload);
    },
    restoreData({ commit }) {
      commit("setRestore");
    },
    editStep({ commit }, payload) {
      commit("setStep", payload);
    },
    updateIsStep({ commit }, payload) {
      commit("setIsStep", payload);
    },
    updateCheckAuthorization({ commit }, payload) {
      commit("setCheckAuthorization", payload);
    },
    updateIsSaveImage({ commit }, payload) {
      commit("setIsSaveImage", payload);
    },
    updateMultimediaCopy({ commit }, payload) {
      commit("setMultimediaCopy", payload);
    },
    updateMessage({ commit }, payload) {
      commit("setMessage", payload);
    },
    updateAbortController({ commit }, payload) {
      commit("setAbortController", payload);
    },
    updateIsError({ commit }, payload) {
      commit("setIsError", payload);
    },
    updateIsSuccess({ commit }, payload) {
      commit("setIsSuccess", payload);
    },
  },
};
