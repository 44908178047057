<template>
  <section class="bg-agrogo-green-2">
    <!-- SECCION NUMERACION -->
    <div
      class="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 bg-agrogo-yellow-2 max-h-88 md:max-h-103"
    >
      <div class="flex flex-col items-center md:flex-row md:pl-20 py-2 md:py-0">
        <a
          class="font-nexa font-black text-5xl md:text-8xl text-agrogo-yellow-2 md:mr-44"
          >03</a
        >
        <div class="flex items-center space-x-4 md:space-x-5 lg:space-x-6">
          <span class="icon-punto text-white md:text-2xl"></span>
          <span class="icon-punto text-white md:text-2xl"></span>
          <span class="icon-punto text-agrogo-green-3 md:text-2xl"></span>
        </div>
      </div>
    </div>
    <!-- imagen de fondo -->
    <section
      v-if="isBusy"
      class="containerGifLoading w-80 md:w-full max-h-88 md:max-h-245"
    >
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <div
      v-if="!isBusy"
      class="bg-agrogo-modal w-full pt-4 md:pt-8 pb-3 px-6 h-auto grid grid-cols-1 md:px-8 md:mb-16"
    >
      <div class="flex mb-5 md:min-h-64 md:bg-agrogo-green-4-5 md:pl-5">
        <span
          class="icon-headphones leading-3 pr-3 pt-1.5 text-sm text-white md:text-xl md:pt-0"
        ></span>
        <p class="font-nexa font-bold text-white text-left text-sm md:text-xl">
          Nuestro equipo se pondrá en contacto contigo a la mayor brevedad
          posible.
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div
          class="md:min-h-102 md:bg-agrogo-green-5 md:px-3 lg:pl-14 lg:px-16 md:pt-5 md:pb-5 md:mr-1 md:mb-2"
        >
          <label
            for="name"
            class="text-xs text-agrogo-gray-1 font-nexa block mb-1 text-left lg:text-lg relative"
            >Nombres
            <span
              class="text-agrogo-yellow-2 text-2xl ml-1 h-0 inline-block absolute"
              >*</span
            ></label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
            >
              <span class="icon-Perfil_2 text-agrogo-black-1 text-sm"></span>
            </div>
            <input
              v-model="$v.formData.name.$model"
              @blur="$v.formData.name.$touch()"
              id="name"
              name="name"
              type="text"
              class="input-login"
            />
          </div>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-if="$v.formData.name.$error"
          >
            {{ messageValidation($v.formData.name) }}
          </p>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-else
          >
            &nbsp;
          </p>
        </div>
        <div
          class="md:min-h-102 md:bg-agrogo-green-5 md:px-3 lg:pl-14 lg:px-16 md:pt-5 md:pb-5 md:ml-1 md:mb-2"
        >
          <label
            for="lastname"
            class="text-xs text-agrogo-gray-1 font-nexa block mb-1 text-left md:mt-0 lg:mt-0 lg:text-lg relative"
            >Apellidos
            <span
              class="text-agrogo-yellow-2 text-2xl ml-1 h-0 inline-block absolute"
              >*</span
            ></label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
            >
              <span class="icon-Perfil_2 text-agrogo-black-1 text-sm"></span>
            </div>
            <input
              v-model="$v.formData.lastname.$model"
              @blur="$v.formData.lastname.$touch()"
              id="lastname"
              name="lastname"
              type="text"
              class="input-login"
            />
          </div>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-if="$v.formData.lastname.$error"
          >
            {{ messageValidation($v.formData.lastname) }}
          </p>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-else
          >
            &nbsp;
          </p>
        </div>
        <div
          class="md:min-h-102 pb-6 md:bg-agrogo-green-5 md:px-3 lg:pl-14 lg:px-16 md:pt-5 md:pb-5 md:mr-1"
        >
          <label
            for="indicativo_telephone"
            class="text-xs text-agrogo-gray-1 font-nexa block mb-1 text-left lg:text-lg relative"
            >Teléfono
            <span class="icon-whatsapp"></span>
            Whatsapp
            <span
              class="text-agrogo-yellow-2 text-2xl ml-1 h-0 inline-block absolute"
              >*</span
            ></label
          >
          <div class="flex">
            <div class="relative w-2/5 mr-2">
              <img :src="img" class="img-code flex items-center" alt="" />
              <v-select
                :options="codes"
                v-model="selected"
                :reduce="(option) => option.id"
                class="style-chooser h-8"
                label="phone_code"
              >
                <template #option="{ phone_code, path_img_iso_large, name }">
                  <div class="flex items-center justify-between">
                    <div class="flex">
                      <img
                        :src="path_img_iso_large"
                        :alt="phone_code"
                        class="mr-2"
                      />
                      <div class="font-medium">{{ phone_code }}</div>
                    </div>
                    <div class="font-medium">
                      <div v-if="name.length > 20">
                        {{ name.slice(0, 20) }}..
                      </div>
                      <div v-else>{{ name }}</div>
                    </div>
                  </div>
                </template>
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
            <div class="relative w-3/5">
              <div
                class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
              >
                <span class="icon-telefono text-agrogo-black-1 text-lg"></span>
              </div>
              <input
                v-model="$v.formData.telephone.$model"
                @blur="$v.formData.telephone.$touch()"
                id="telephone"
                name="telephone"
                type="number"
                class="input-login"
                :class="{ 'error-form': $v.formData.telephone.$error }"
              />
            </div>
          </div>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-if="$v.formData.telephone.$error"
          >
            {{ messageValidation($v.formData.telephone) }}
          </p>
          <p
            class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
            v-else
          >
            &nbsp;
          </p>
        </div>
        <div
          class="flex pb-8 md:min-h-102 md:bg-agrogo-green-5 md:px-3 lg:pl-14 lg:px-16 md:pt-5 md:pb-5 md:ml-1"
        >
          <div class="flex pt-1 lg:pt-2">
            <input
              v-model="$v.formData.verify_tc.$model"
              @blur="$v.formData.verify_tc.$touch()"
              :true-value="true"
              :false-value="false"
              id="verify_whatsapp"
              name="verify_whatsapp"
              type="checkbox"
              class="custom-checkbox-test"
            />
          </div>
          <div class="flex items-end text-left md:items-center">
            <label
              for="verify_whatsapp"
              class="text-white text-sm font-dinpro font-normal block ml-3 lg:text-lg"
            >
              <span
                v-if="$v.formData.verify_tc.$invalid"
                class="text-agrogo-yellow-2"
                >*</span
              >
              <span
                >Acepto
                <router-link
                  class="underline underline-offset-4"
                  to="/tratamiento-de-datos-personales"
                  target="_blank"
                >
                  tratamiento de datos personales </router-link
                >,
                <router-link
                  class="underline underline-offset-4"
                  to="/politicas-de-tratamiento-de-datos-personales"
                  target="_blank"
                >
                  políticas de privacidad
                </router-link></span
              >
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR BOTONES DE NAVEGACION -->
    <div
      v-if="!isBusy"
      class="flex items-center justify-center bg-agrogo-black-2 space-x-4 md:space-x-4 lg:space-x-4 pb-14 md:bg-agrogo-green-2 md:pb-8"
    >
      <button
        @click.prevent="() => $emit('previus', 'p3')"
        type="button"
        class="btn-nav-leads"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-xs mr-1 leading-none md:mr-2 md:text-sm"
        ></span>
        Anterior
      </button>
      <button
        @click="saveDataLead()"
        :disabled="$v.$invalid || isBusy2"
        type="button"
        class="btn-nav-leads"
      >
        <div class="loader" v-if="isBusy2" />
        <span v-else>Siguiente</span>
      </button>
    </div>
  </section>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";

export default {
  inject: ["provider"],
  mixins: [validationMixin, loginRegisterMixin],
  props: {
    isBusy2: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("leadSteps", ["leadStep3"]),
  },
  data() {
    return {
      $v: useVuelidate(),
      /**
       * Save the form data
       * @type {Object}
       */
      formData: {
        name: "",
        lastname: "",
        telephone: "",
        code_area_id: null,
        type_user_id: "2" /* Aqui se indica el tipo de usuario*/,
        verify_tc: false,
      },
      /**
       * Turn loading gif on or off
       * @type {Boolean}
       */
      isBusy: false,
      /**
       *Allows access to all the methods that establish the connection to Axios for requests to the agrogo api
       * @type {Object}
       */
      repository: this.provider.authRepository,
      /**
       *save the list of country codes
       * @type {Array<Object>}
       */
      codes: [],
    };
  },
  validations() {
    return {
      formData: {
        name: {
          required,
        },
        lastname: {
          required,
        },
        code_area_id: {
          required,
        },
        telephone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        verify_tc: {
          checked(val) {
            return val;
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("leadSteps", ["actionLeadStep3"]),
    /**
     * Query the agrogo api and get the list of country code
     * @returns {void}
     */
    async loadCodeAreaAcount() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAdicional = await generateHash();
        const { data } = await me.repository.getAll(dataAdicional);

        data.data.forEach((el) => {
          el.phone_code = `+${el.phone_code}`;
          el.path_img_iso_medium = `${API_ROUTES.image.get}${el.path_img_iso_medium}`;
          el.path_img_iso_large = `${API_ROUTES.image.get}${el.path_img_iso_large}`;
        });
        me.codes = data.data;
        me.formData.code_area_id = 44;
        me.selected = 44;
        me.loadImg(me.formData.code_area_id);
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * save the form information in the state leadStep3 using the action
     * @returns {void}
     */
    saveDataLead() {
      const me = this;
      me.actionLeadStep3(me.formData);
      me.$emit("send");
    },
    /**
     * retrieves the information from the state leadStep3 to assign it to the form
     * @returns {void}
     */
    getDataLead() {
      const me = this;
      const data = me.leadStep3;
      if (me.leadStep3 != null) {
        me.formData = data;
        me.selected = data.code;
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadCodeAreaAcount();
    await me.getDataLead();
  },
};
</script>

<style>
.md\:text-white {
  color: #ffffff !important;
}
</style>
