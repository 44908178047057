<template>
  <main>
    <router-view />
    <Footer v-if="isVisible" />
    <modal-login-dev
      v-if="isModalLoginDev"
      :isModalLoginDev="isModalLoginDev"
      @closeModalLoginDev="isModalLoginDev = false"
    />
  </main>
</template>

<script>
import { generateHash } from "@/utils";
import { mapState } from "vuex";
import Footer from "@/components/Footer";
import ModalLoginDev from "@/components/modals/ModalLoginDev.vue";
export default {
  name: "App",
  components: {
    Footer,
    ModalLoginDev,
  },
  data() {
    return {
      isVisible: false,
      debug: process.env.VUE_APP_DEBUG,
      isModalLoginDev: false,
    };
  },
  watch: {
    "$route.name": {
      handler: function () {
        const me = this;
        me.loadFooter();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
    ...mapState("preLaunch", ["isLoad"]),
  },
  methods: {
    async loadTimezone() {
      const me = this;
      try {
        await generateHash();
        if (me.isPreLaunch) {
          me.$router.push("/inicio-de-sesion");
        }
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    blockRightClick() {
      // const app = document.getElementById("app");

      window.onload = function () {
        document.addEventListener(
          "contextmenu",
          function (e) {
            e.preventDefault();
          },
          false
        );
      };

      // app.addEventListener("click", () => {
      //   alert("click izquierdo");
      // });
    },
    loadFooter() {
      const me = this;
      const { name } = me.$route;
      me.isVisible =
        name !== "sitemap" && name !== "sitemapPage" ? true : false;
    },
  },
  mounted() {
    const me = this;
    me.loadFooter();
    me.loadTimezone();
    if (localStorage.getItem("loginDev")) {
      me.isModalLoginDev = false;
    } else {
      me.isModalLoginDev = me.debug === "true" ? true : false;
    }
    // me.blockRightClick();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
