import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import "@/assets/css/style.css";
import "vue-select/dist/vue-select.css";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import InstantSearch from "vue-instantsearch/vue3/es";
import "sweetalert2/dist/sweetalert2.min.css";
import RepositoryProvider from "@/provider/RepositoryProvider";
import {
  createMetaManager,
  defaultConfig,
  plugin as metaPlugin,
} from "vue-meta";

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: "meta", nameless: true },
});

createApp(App)
  .component("vSelect", vSelect)
  .provide("provider", RepositoryProvider)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(InstantSearch)
  .use(metaManager)
  .use(metaPlugin)
  .mount("#app");
