import { API_ROUTES } from "@/config";

export default {
  data() {
    return {
      meta: {
        meta_title: "Contactos | AGRO GO",
        meta_description: null,
        key_words: null,
        meta_url: null,
        url_featured_image: null,
      },
      dataMeta: {},
    };
  },
  methods: {
    loadDataMeta() {
      const me = this;
      me.dataMeta = {
        title: me.meta?.meta_title,
        meta: [
          { name: "robots", content: "index,follow" },
          { property: "og:locale", content: "es_ES" },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: "Agro Go" },
          {
            property: "description",
            content: me.meta?.meta_description,
          },
          // Open Graph de facebook
          {
            property: "og:url",
            content: me.meta?.meta_url,
          },
          {
            property: "og:title",
            content: me.meta?.meta_title,
          },
          {
            property: "og:description",
            content: me.meta?.meta_description,
          },
          {
            property: "og:image",
            content: `${API_ROUTES.image.get}${me.meta?.url_featured_image}`,
          },
          {
            property: "og:image:width",
            content: "1200",
          },
          {
            property: "og:image:height",
            content: "630",
          },
          // *************************
          //Open Graph de twitter
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: me.meta?.meta_url },
          { name: "twitter:title", content: me.meta?.meta_title },
          {
            name: "twitter:description",
            content: me.meta?.meta_description,
          },
          {
            name: "twitter:image",
            content: `${API_ROUTES.image.get}${me.meta?.url_featured_image}`,
          },
          // **********************
          {
            name: "keywords",
            content: me.meta?.key_words,
          },
        ],
      };
    },
  },
};
