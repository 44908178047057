<template>
  <p
    class="font-nexa font-normal text-sm text-white pl-8 text-left bg-agrogo-green-1 pt-2.5 md:text-2xl md:px-24"
  >
    <span class="font-black">Tipo de</span> Finca
  </p>
  <div
    class="flex w-auto h-auto bg-agrogo-green-1 pb-2 pt-1.5 md:px-7 md:pb-0 justify-center"
  >
    <div
      class="items-center px-2 pt-5 overflow-x-auto flex space-x-1 md:space-x-3.5 md:py-0 md:pt-2 hide-scrollbar"
    >
      <div
        v-for="(item, index) in category.subcategories"
        :key="index"
        @click.prevent="() => $emit('close')"
      >
        <router-link
          class="card-slider w-20 overflow-hidden flex flex-col items-center md:w-32 text-agrogo-gray-1 hover:text-agrogo-yellow-1"
          :to="{
            name: 'ListOfFarms',
            query: {
              buscar: `${item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()}-${item.id}`,
            },
          }"
        >
          <img
            class="icon-slider mb-2"
            :id="'icon-' + item.id"
            :src="loadImg(item.id)"
            alt=""
          />
          <!-- <span
            class="icon-slider text-4xl text-agrogo-yellow-1 mb-2 md:text-5xl"
            :id="'icon-' + item.id"
            :class="item.icon"
          ></span> -->
          <div class="font-nexa font-normal mb-2 text-xs md:text-base">
            {{ item.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SliderTypeFarm",
  computed: {
    ...mapState("farmTypes", ["category"]),
  },
  props: {
    categoryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      categoryId2: null,
    };
  },
  watch: {
    categoryId() {
      const me = this;
      if (me.categoryId) {
        me.categoryId2 = me.categoryId;
        me.loadClass();
      } else {
        me.deleteStyle();
      }
    },
  },
  methods: {
    searchFarm(item) {
      const me = this;
      me.$router.push({
        name: "ListOfFarms",
        query: {
          buscar: `${item.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()}-${item.id}`,
        },
      });
      me.$emit("close");
    },
    isKeyExists(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key);
    },
    loadImg(id) {
      return require(`@/assets/images/iconTypeFarm/${id}.svg`);
    },
    loadClass() {
      const me = this;
      const icon = document.querySelectorAll(".icon-slider");
      const iconId = document.getElementById("icon-" + me.categoryId2);

      icon.forEach((el) => {
        el.classList.forEach((x) => {
          if (x === "active-icon-slider")
            el.classList.remove("active-icon-slider");
        });
      });

      if (iconId) {
        iconId.classList.forEach((x) => {
          x === "active-icon-slider"
            ? iconId.classList.remove("active-icon-slider")
            : iconId.classList.add("active-icon-slider");
        });
      }
    },
    deleteStyle() {
      const card = document.querySelectorAll(".icon-slider");

      card.forEach((el) => {
        el.classList.forEach((x) => {
          if (x === "active-icon-slider")
            el.classList.remove("active-icon-slider");
        });
      });
    },
  },
};
</script>
<style lang="css">
.card-slider {
  padding-top: 5px;
}
.icon-slider {
  /* background: red; */
  /* width: 66px !important; */
  /* height: 55px !important; */
}
.icon-slider.active-icon-slider {
  filter: drop-shadow(0 2px 5px rgba(245, 206, 62, 1)) !important;
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  /* .card-slider {
    padding-top: 0px;
  } */
}
</style>
