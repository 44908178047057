<template>
  <Transition name="slide-fade">
    <div
      class="fixed z-index-999 inset-0 overflow-y-auto w-screen"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isModalLoginDev"
    >
      <div
        class="flex items-end justify-center h-96 pt-32 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-black-trasparent transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <Transition name="slide-fade">
          <div
            class="relative bg-transparent inline-block align-bottom shadow-xl transform transition-all my-8 sm:align-middle sm:max-w-sm sm:w-full md:max-w-sm lg:max-w-sm"
            v-if="isModal"
          >
            <section class="bg-agrogo-green-2 text-white rounded-md p-4">
              <h1
                class="text-lg text-white font-nexa font-bold text-center mb-2 md:text-2xl"
              >
                Inicio de Sesión para Desarrollador
              </h1>
              <h2
                class="text-base text-white font-nexa font-bold text-left mb-2"
              >
                Si no eres Desarrollador, debes ingresar a
                <a
                  href="https://fincas.agrogo.com.co/"
                  class="text-agrogo-yellow-1"
                  id="link-login-dev"
                  >https://fincas.agrogo.com.co/</a
                >
              </h2>
              <form class="mt-4" @submit.prevent="login">
                <div class="text-left mb-6">
                  <label
                    for="password"
                    class="text-xs text-white font-nexa font-normal block ml-2.5 mb-1 md:mb-2 md:text-base md:ml-5"
                    >Contraseña</label
                  >
                  <div class="relative">
                    <div
                      class="flex absolute inset-y-0 right-0 items-center pr-3"
                    >
                      <span
                        @click.prevent="isShow = !isShow"
                        class="icon-see text-agrogo-black-1 text-base cursor-pointer"
                      ></span>
                    </div>
                    <input
                      v-model="v$.formData.password.$model"
                      @blur="v$.formData.password.$touch()"
                      id="password"
                      name="password"
                      :type="isShow ? 'text' : 'password'"
                      class="input-login"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    class="btn-login flex justify-center items-center mb-2"
                    :disabled="isBusy || v$.$invalid"
                  >
                    <div class="loader" v-if="isBusy" />
                    Iniciar sesión
                  </button>
                </div>
              </form>
            </section>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
// Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  emits: ["closeModalLoginDev"],
  props: {
    isModalLoginDev: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      isModal: me.isModalLoginDev,
      isShow: false,
      isBusy: false,
      formData: {
        password: "",
      },
      passwordDev: process.env.VUE_APP_PASSWORD_DEV,
    };
  },
  validations() {
    return {
      formData: {
        password: {
          required,
        },
      },
    };
  },
  watch: {
    isModalLoginDev(newData) {
      this.isModal = newData;
    },
  },
  methods: {
    login() {
      const me = this;
      if (!me.v$.$invalid) {
        me.isBusy = true;
        if (me.passwordDev === me.formData.password) {
          localStorage.setItem("loginDev", true);
          me.$emit("closeModalLoginDev");
          me.isBusy = false;
        } else {
          const link = document.getElementById("link-login-dev");
          link.click();
        }
      }
    },
  },
};
</script>

<style>
.bg-black-trasparent {
  background: rgba(0, 0, 0, 0.7);
}
.z-index-999 {
  z-index: 999;
}
</style>
